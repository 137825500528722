import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  Button,
  Column,
  Container,
  FacetFilters,
  MobileModalWrapper,
  SelectByOptions
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useFilterCategories, useFilter, useOrder } from 'shared/utils/hooks'
import {
  ContentfulResponse,
  generateBreadcrumbJoin,
  IContentfulResponse,
  isValidArrayWithData,
  useAndesML,
  useCatalogFacets,
  useCategories,
  useContentful,
  usePagination,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import {
  categoriesForBreadcrumbs,
  changeUrlCategories,
  contentBannerLegal,
  getQueryKeyToRequest,
  isContinuosFetching,
  matchOptionKey,
  orderByOptions,
  paginationCal,
  promotionsOfShelves,
  getInitialFilters
} from 'shared/helpers'
import {
  BodyPageProducts,
  BodyPageProductsProps,
  ErrorPageV2Props,
  LegalTerms,
  PlpDescription
} from '..'
import { REACT_QUERY_GENERAL } from 'shared/constants/reactQuery'
import { useFacets } from 'shared/utils/hooks/useFacets'
import { CAMPAING_APP_CHIPS_UNIMARC } from 'shared/utils/constanst'
import { MonetiseBannerPLP } from './helpers'

const OrderByRadioButtonList = dynamic<SelectByOptions>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.OrderByRadioButtonList
  })
)

const ErrorPage = dynamic<ErrorPageV2Props>(
  import('..').then((mod) => {
    return mod.ErrorPageV2
  })
)

interface ProductListPageProps {
  categories: Array<string>
  categoriesId?: string
  firstLoad?: number
  isMobile?: boolean
  page: number
  handleFirstLoad?: () => void
}

const initialLimit = 49

export const ProductListPage = ({
  categories,
  categoriesId,
  firstLoad,
  isMobile,
  handleFirstLoad
}: ProductListPageProps) => {
  const router = useRouter()
  const [categoriesTree, setCategoriesTree] = useState(categoriesId)
  const {
    applyFilters,
    compareAndSetInitialData,
    currentSelectedFilters,
    filterData
  } = useFilter(router) || {}

  const { handleClickCategoryPill, place, treeValue } = useFilterCategories(
    router,
    categoriesId
  )

  const {
    onClickFilter,
    onClickFilterModal,
    onClickRadioOrderByOption,
    showModal,
    onApplyFilterModal,
    selectedRadio,
    disabledButton
  } = useOrder(router)

  const { showFacets, handleOpenFacetModal } = useFacets()
  const page = parseInt(router?.query?.page as string) || 1
  const { offset, end: limit } = paginationCal({ page, limit: 50 })
  const nextLimit = offset + initialLimit
  const [resources, setResources] = useState(Infinity)
  const [offSet, setOffSet] = useState(offset)
  const [countLimit, setCountLimit] = useState(offSet + initialLimit)
  const [products, setProducts] = useState([])
  const orderByFilterName = matchOptionKey(
    router?.query?.orderBy?.toString() ?? ''
  )
  const resultPromotions = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE,
      'sys.id[in]': CAMPAING_APP_CHIPS_UNIMARC[0]
    },
    reactQuery: REACT_QUERY_GENERAL
  })

  // clean the object of promotions
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  // get all categories menu like with getCategories service
  const resultAisles = useCategories({
    level: 2,
    reactQuery: REACT_QUERY_GENERAL
  })
  // get a clean object of categories and return breadcrumbs and pills
  const {
    breadcrumbData,
    subCategories,
    title,
    categoryId = ''
  } = categoriesForBreadcrumbs({
    router,
    categories,
    resultAisles
  })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)
  const reactQueryByCategory = {
    ...REACT_QUERY_GENERAL,
    enabled: Boolean(categoryId) && offSet < countLimit
  }
  const keysToQueryCFbanner = getQueryKeyToRequest([
    {
      value: categoryId
    },
    {
      value: 'plpBannerLegal'
    },
    {
      value: categoriesId
    }
  ])
  // get data to banner legal
  const contentCategories = useContentful({
    options: {
      'fields.idFormato': 1,
      'fields.referenceType': 'category',
      'fields.referencia': categoryId,
      content_type: 'plpBannerLegal'
    },
    reactQuery: { ...reactQueryByCategory, queryKey: keysToQueryCFbanner }
  })

  const contentSeoPlp = useContentful({
    options: {
      'fields.platform': 'Web Unimarc eComm',
      'fields.referenceId': categoryId,
      'fields.referenceType': 'category',
      content_type: 'landingpagesParaLaWeb'
    },
    reactQuery: { ...reactQueryByCategory, staleTime: 0 }
  })

  const seoPlpData = contentSeoPlp.data
    ? new ContentfulResponse(contentSeoPlp.data as IContentfulResponse)
        .populateEntries()
        .getResponse()
    : []

  const initialFilters = getInitialFilters(router?.query)
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    {
      value: {
        ...currentSelectedFilters,
        ...applyFilters
      }
    },
    { value: categoriesTree },
    { value: offset.toString() },
    { value: countLimit.toString() },
    { value: orderByFilterName }
  ])

  const { bannerImages, contentLegalParagraph, contentLegalTitle } =
    contentBannerLegal(contentCategories?.data)

  const productsSearch = useProductsSearch({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: categoriesTree,
    from: offSet.toString(),
    to: countLimit.toString(),
    orderBy: orderByFilterName,
    reactQuery: {
      ...reactQueryByCategory,
      queryKey: keysToQuery
    }
  })
  const bannerAndesMl = useAndesML({
    categoryName: categoriesTree,
    deviceType: isMobile ? 'MOBILE' : 'DESKTOP',
    pageType: 'PLP',
    requestPage: `${process.env.NEXT_PUBLIC_HOMEURL}${router.asPath}`,
    slot: 'TOP BANNERS',
    reactQuery: {
      queryKey: ['getAndesMl', categoriesTree],
      ...REACT_QUERY_GENERAL
    }
  })
  const bannerAndesMLData = bannerAndesMl?.data?.[0] || {}
  const responseFacetsData = useCatalogFacets({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: categoriesTree,
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: keysToQuery
    }
  })

  const isLoadingFacets =
    responseFacetsData?.isLoading || responseFacetsData?.isFetching
  const urlLinkCategory =
    contentCategories?.data?.['items'][0]?.fields?.urlRedirect
  const continuosFetching: boolean = isContinuosFetching({
    countLimit,
    resources,
    limit
  })
  const data = contentCategories?.data?.['items'][0]?.fields
  const bannerDataInfo =
    Object.entries(bannerAndesMLData)?.length > 0 ? bannerAndesMLData : data
  const totalQuantity: number = Number(productsSearch?.data?.resource) || 0
  const facetsFilterV2 = responseFacetsData?.data || {}
  const isLoadingPage =
    resultAisles.isLoading ||
    productsSearch.isLoading ||
    productsSearch.isFetching
  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: () => {
      const newCountLimit = nextLimit > limit ? limit : nextLimit
      setOffSet(offset)
      setCountLimit(newCountLimit)
    }
  })

  const handleFetchProducts = () => {
    if (!productsSearch.isLoading && continuosFetching) {
      setOffSet(countLimit + 1)
      setCountLimit((prev) => {
        const defaultLimit = limit > resources ? resources : limit
        const nextLimit = prev + initialLimit + 1
        return nextLimit > limit || nextLimit > resources
          ? defaultLimit
          : nextLimit
      })
    }
  }

  const dataOfBodyPageProducts: BodyPageProductsProps = {
    bannerAndesMl: bannerDataInfo,
    bannerImages,
    breadcrumbData: dataBreadCrumb,
    continuosFetching,
    dataPromotions,
    facetsFilterV2,
    filterData,
    firstLoad,
    handleFirstLoad,
    handleOnSelectRange: filterData.handleOnSelectRange,
    handleOnTogglePromotions: filterData.handleOnTogglePromotions,
    handleOpenFacetModal,
    hasFacets: filterData.hasFilter,
    imageAndes: bannerAndesMLData?.imageUrl,
    isLoadingBannerAndes: bannerAndesMl.isLoading,
    isLoadingFacets,
    isLoadingPage,
    isMobile,
    key: categoriesId,
    onClickCategoryPill: handleClickCategoryPill,
    onClickFilter,
    onClickFilterModal,
    onClickRadioOrderByOption,
    place,
    products,
    propsNavigation,
    resultAisles: resultAisles,
    selectedRadio,
    setCountLimit: handleFetchProducts,
    subCategories,
    title,
    totalQuantity,
    urlLink: bannerAndesMLData?.redirectUrl || urlLinkCategory
  }

  useEffect(() => {
    compareAndSetInitialData(facetsFilterV2)
  }, [facetsFilterV2])

  useEffect(() => {
    const {
      availableProducts = [],
      notAvailableProducts = [],
      resource: count
    } = productsSearch?.data || {}
    const newProducts = [...availableProducts, ...notAvailableProducts]
    if (count && parseInt(count) !== resources) setResources(parseInt(count))
    setProducts((prev) => {
      const prevData = continuosFetching ? prev : []
      return isLoadingPage && continuosFetching
        ? []
        : [...prevData, ...newProducts]
    })
  }, [productsSearch?.data, isLoadingPage, countLimit])

  useEffect(() => {
    if (router?.query?.categories?.length === 1) {
      setCategoriesTree(categoriesId)
    } else if (router?.query?.categories?.length > 1) {
      if (treeValue !== undefined) {
        setCategoriesTree(treeValue)
      } else {
        setCategoriesTree(categoriesId)
      }
    }
  }, [router.query])

  useEffect(() => {
    propsNavigation.setPage(page)
  }, [categories, page])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  useEffect(() => {
    if (!router?.query?.orderBy) {
      onClickRadioOrderByOption('Recomendados')
    }
  }, [router?.query?.orderBy])

  // when service in client-side response with error property
  if (
    !isLoadingPage &&
    (productsSearch?.isError ||
      (productsSearch?.error && !productsSearch?.data?.resource))
  ) {
    return <ErrorPage errorType='400' />
  }

  // when service in client-side response a 404 or no data
  if (
    !isLoadingPage &&
    productsSearch?.error &&
    !isValidArrayWithData(productsSearch?.data?.availableProducts)
  ) {
    return <ErrorPage errorType='404' />
  }

  return (
    <>
      <Container
        alignItems='start'
        justifyContent='center'
        tagName='main'
      >
        <BigScreen>
          <Column alignItems='center'>
            {!bannerAndesMl.isLoading && (
              <MonetiseBannerPLP
                data={bannerDataInfo}
                imageAndes={bannerAndesMLData?.imageUrl}
                images={bannerImages}
                isLoadingPage={isLoadingPage}
                key={categoriesId}
                padding='32px 0 0 0'
                resultAisles={resultAisles}
                urlLink={bannerAndesMLData?.redirectUrl || urlLinkCategory}
              />
            )}
            <Container
              backgroundColor={getGlobalStyle('--color-background-white')}
              isWrap
              maxWidth={getGlobalStyle('--width-max-desktop')}
              padding='10px 0px'
              width='1120px'
            >
              <Column alignItems='center'>
                <BodyPageProducts
                  key={`PLP-${categories}`}
                  {...dataOfBodyPageProducts}
                />
              </Column>
            </Container>
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Column>
        </BigScreen>

        <SmallScreen>
          <Column backgroundColor={getGlobalStyle('--color-base-white')}>
            {showModal && (
              <MobileModalWrapper
                blockId='orderBy'
                body={
                  <OrderByRadioButtonList
                    onClick={onClickRadioOrderByOption}
                    options={orderByOptions}
                    selected={selectedRadio}
                  />
                }
                headerTitle='Ordena tus productos'
                iconSize={20}
                isEnableButton={!disabledButton}
                modalConfigsProps={{
                  toggle: onClickFilterModal,
                  isOpen: showModal,
                  toggleOutside: onClickFilterModal
                }}
                onApply={onApplyFilterModal}
                onClose={onClickFilterModal}
              />
            )}
            {showFacets && (
              <MobileModalWrapper
                blockId='facets'
                body={
                  <FacetFilters
                    {...filterData}
                    isMobile={isMobile}
                  />
                }
                headerLeftElement={
                  filterData.hasFilter && (
                    <Column
                      flexGrow={0}
                      width='auto'
                    >
                      <Button
                        color={getGlobalStyle('--color-primary-light-red')}
                        fontSize='12px'
                        fontWeight='600'
                        height='initial'
                        label='Limpiar'
                        minWidth='auto'
                        onClick={filterData.handleClearOnClick}
                        type='plain'
                      />
                    </Column>
                  )
                }
                headerTitle='Selecciona tus filtros'
                iconSize={20}
                isEnableButton={filterData.enableButton}
                modalConfigsProps={{
                  isOpen: showFacets,
                  toggle: handleOpenFacetModal,
                  toggleOutside: handleOpenFacetModal
                }}
                onApply={() => {
                  return filterData.handleApplyOnClick(handleOpenFacetModal)
                }}
                onClose={handleOpenFacetModal}
              />
            )}
            <BodyPageProducts {...dataOfBodyPageProducts} />
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Column>
        </SmallScreen>
      </Container>
      {isValidArrayWithData(seoPlpData?.['items']) && (
        <PlpDescription
          isMobile={isMobile}
          items={seoPlpData['items']}
        />
      )}
    </>
  )
}
