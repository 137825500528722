import { Backbone, Column, Link, Row } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  eventClick,
  useOrderForm,
  useTrigerEvent
} from '@smu-chile/pkg-unimarc-hooks'

export const CTAClearCart = (): React.ReactElement => {
  const { isLoading } = useOrderForm()
  const { trigger } = useTrigerEvent()

  const clearCart = () => {
    trigger({ eventType: 'clearCart', data: { show: true } })
    eventClick({
      event: 'interacciones_carrito',
      eventAction: 'navegacion_compra',
      eventCategory: 'carrito',
      eventLabel: 'vaciar carro'
    })
  }

  if (isLoading)
    return (
      <Row justifyContent='center'>
        <Column
          minHeight='30px'
          width='78px'
        >
          <Backbone
            backgroundColor={getGlobalStyle('--color-neutral-gray')}
            borderRadius={getGlobalStyle('--border-radius-xs')}
            height={19}
            width={100}
          />
        </Column>
      </Row>
    )

  return (
    <Row>
      <Column
        alignItems='center'
        tagName='section'
      >
        <Link
          color={getGlobalStyle('--color-base-black')}
          fontSize='md'
          href='#'
          label='Vaciar carro'
          onClick={clearCart}
        />
      </Column>
    </Row>
  )
}
