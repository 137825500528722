import { useEffect, useRef } from 'react'
import {
  Container,
  Coupon,
  CouponProps
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  getCouponName,
  initCouponsImpression,
  TaggableCoupon
} from '@smu-chile/pkg-unimarc-hooks'
import { CouponSimpleListLoader } from './CouponListLoader/CouponListLoader'

export interface CouponSimpleListProps {
  coupons: CouponProps[]
  isLoading?: boolean
  selectedFilter?: string
}

export const CouponSimpleList = ({
  coupons = [],
  isLoading,
  selectedFilter
}: CouponSimpleListProps) => {
  const container = useRef()
  const couponsToActivate = useRef<TaggableCoupon<HTMLButtonElement>[]>([])

  const assignCouponRef = (
    ref: HTMLButtonElement | HTMLAnchorElement,
    coupon: CouponProps,
    index: number
  ) => {
    const taggableCoupon: TaggableCoupon = {
      ref,
      id: coupon.id,
      index: `${coupon.index + 1}`,
      name: getCouponName(coupon)
    }
    if (coupon.status === undefined && ref instanceof HTMLButtonElement) {
      couponsToActivate.current[index] =
        taggableCoupon as TaggableCoupon<HTMLButtonElement>
    }
  }

  useEffect(() => {
    if (!selectedFilter)
      return () => {
        return
      }
    const removeEventListener = initCouponsImpression({
      coupons: couponsToActivate.current,
      creativeName: 'boton-activar',
      eventKey: 'boton-activar',
      locationId: `shelf-cupones-${selectedFilter}`,
      couponType: selectedFilter
    })

    couponsToActivate.current = []
    return removeEventListener
  }, [couponsToActivate.current, selectedFilter])

  if (isLoading) {
    return <CouponSimpleListLoader />
  }

  return (
    <>
      {coupons.map((coupon, index) => {
        return (
          <Container
            customHeight='100%'
            key={index}
            ref={container}
          >
            <SmallScreen>
              <Coupon
                activeButtonRef={(ref) => {
                  return assignCouponRef(ref, coupon, index)
                }}
                {...coupon}
              />
            </SmallScreen>
            <BigScreen>
              <Coupon
                activeButtonRef={(ref) => {
                  return assignCouponRef(ref, coupon, index)
                }}
                viewProductsRef={(ref) => {
                  return assignCouponRef(ref, coupon, index)
                }}
                {...coupon}
                customHeight='100%'
              />
            </BigScreen>
          </Container>
        )
      })}
    </>
  )
}
