import { Text } from '@smu-chile/pkg-unimarc-components'
import { currencyFormat } from '@smu-chile/pkg-unimarc-hooks'

export interface PriceProps {
  price: string | number
}

export const Price = ({
  price
}: PriceProps): React.ReactElement<PriceProps> => {
  return (
    <Text
      color='guardsman-red'
      fontSize='md'
      fontWeight='semibold'
    >{`${currencyFormat({ number: price, bool: false })} c/u`}</Text>
  )
}
