import { useCallback, useEffect } from 'react'
import {
  AxiosResponse,
  IItem,
  IProductsV2,
  IResponse,
  patchCoupon,
  sleep,
  triggerLoginEvent,
  useEvents,
  useQueryClient,
  useTrigerEvent
} from '@smu-chile/pkg-unimarc-hooks'
import {
  ICoupon,
  ICouponObject,
  ICouponV2
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import { CouponProps, CouponStatus } from '@smu-chile/pkg-unimarc-components'
import { transformCouponLineThrough } from '@smu-chile/pkg-unimarc-components/helpers'
import { CustomViewError } from 'components/ErrorBoundary'

export const COUPON_DEFAULT_IMAGE =
  'https://images.ctfassets.net/un6yvtd6uq5z/4QSScvaas23f1ucmgxGoDT/04203f555c88f7b67726e33897bda89f/placeholder.png?h=250'

export const statusMap: Record<string, string> = {
  '1': 'UNACTIVATED',
  '2': 'ACTIVATED',
  '3': 'USED'
}

export const statusMapV2: Record<string, CouponStatus> = {
  UNACTIVATED: undefined,
  ACTIVATED: 'active',
  USED: 'used'
}

export enum ChannelEnum {
  all = '',
  only_app = 'Exclusivo online',
  only_store = 'Solo tienda'
}

export const getCouponProps = (
  coupon: ICouponObject,
  index: number
): CouponProps => {
  return {
    id: coupon.id,
    badgeText: ChannelEnum[coupon?.channel ?? 'all'] || coupon?.channel,
    description: coupon?.description,
    imageSrc: coupon?.image || COUPON_DEFAULT_IMAGE,
    lead: coupon?.lead,
    status: statusMapV2[coupon?.status],
    subtitle: coupon?.subTitle,
    title: coupon?.title,
    index: index,
    viewProductsText:
      coupon.productIds.length === 0 ? 'Ver detalles' : 'Ver productos',
    unipay: coupon?.unipay,
    ...transformCouponLineThrough(coupon?.lineThrough || [])
  }
}

export const useHandleActiveCoupon = (
  isLoggedIn,
  coupons,
  loadingCoupon,
  selectedCouponId,
  setLoginModalOpen?
) => {
  const { trigger } = useTrigerEvent()
  return useCallback(
    async (couponId: string) => {
      if (!isLoggedIn) {
        setLoginModalOpen && setLoginModalOpen(true)
        triggerLoginEvent({
          eventType: 'loginModal',
          data: { show: true, ref: 'activacion cupon' }
        })
        return
      }
      trigger({
        eventType: 'couponUpdate',
        data: {
          id: couponId,
          isLoading: true
        }
      })

      const activation = await patchCoupon(couponId)

      await sleep(1500)
      if (activation?.data?.error) {
        trigger({
          eventType: 'couponUpdate',
          data: {
            id: couponId,
            isLoading: false
          }
        })
      } else {
        trigger({
          eventType: 'couponUpdate',
          data: {
            id: activation?.data?.data?.id,
            isLoading: false,
            status: activation?.data?.data?.status
          }
        })
      }
    },
    [isLoggedIn, coupons, loadingCoupon, selectedCouponId]
  )
}

export const useLoadingCoupons = (couponsResult) => {
  useEffect(() => {
    if (couponsResult?.data?.status === 404) {
      throw new CustomViewError({
        title: 'Ooops..',
        body1: 'No pudimos cargar tus cupones',
        body2: 'Por favor intenta de nuevo',
        errorType: '404',
        errorImage: {
          width: { desktop: '400px', mobile: '06px' },
          height: { desktop: '408px', mobile: '200px' },
          img: 'https://images.ctfassets.net/un6yvtd6uq5z/1GBIXvn6RU33HcVojo6wy2/4f78e41d4cec8dc21f1de7f3c95a163c/Group_7935.svg',
          title: 'Ooops..'
        },
        onClick: () => {
          return window.location.reload()
        }
      })
    }
  }, [couponsResult])
}

export const useHandleEvents = (setLoadingCoupon) => {
  const queryClient = useQueryClient()
  useEvents({
    eventType: 'couponUpdate',
    callBack: async ({ detail: { id, isLoading, status } }) => {
      if (isLoading) {
        setLoadingCoupon((current) => {
          return [...current, id]
        })
      } else {
        setLoadingCoupon((current) => {
          const currentIndex = current.findIndex((currentCouponId) => {
            return currentCouponId === id
          })

          return [
            ...current.slice(0, currentIndex),
            ...current.slice(currentIndex + 1)
          ]
        })
      }
      if (status) {
        const prevCoupons: AxiosResponse<IResponse<ICoupon[]>> =
          queryClient.getQueryData(['coupons'])
        if (prevCoupons?.data?.data?.length > 0) {
          const couponList = prevCoupons.data.data
          const couponIndex = couponList.findIndex((c) => {
            return c.id === id
          })
          couponList[couponIndex].status = status
          queryClient.setQueryData(['coupons'], {
            ...prevCoupons,
            data: {
              ...prevCoupons.data,
              data: [...couponList]
            }
          })
        }
      }
    }
  })
}

export const useFetchCoupons = (
  isLoading,
  coupons,
  isLoggedIn,
  setCouponsLoading,
  couponsResult
) => {
  useEffect(() => {
    if (isLoading) {
      setCouponsLoading(true)
    } else {
      if (couponsResult.data?.headers['x-snap-status'] === 'Pending') {
        setCouponsLoading(true)
        setTimeout(() => {
          couponsResult.refetch()
        }, 1000)
      } else {
        setCouponsLoading(false)
      }
    }
  }, [isLoading, coupons, isLoggedIn])
}

export const transformCoupons = (
  couponsDataList: ICoupon[] | ICouponV2[],
  version?
) => {
  const coupons: ICouponObject[] = []
  if (couponsDataList) {
    if (version === 'V2') {
      couponsDataList?.map((coupon) => {
        const isUrl =
          /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
            coupon.image
          )
        coupons.push({
          id: coupon.id,
          channel: ChannelEnum[coupon?.channel ?? 'all'] || coupon?.channel,
          countUsed: coupon.countUsed,
          description: coupon.description,
          endDate: coupon.endDate,
          image: isUrl ? coupon.image : COUPON_DEFAULT_IMAGE,
          legal: coupon.legal,
          maximumUnits: coupon.maximumUnits,
          maximumUses: coupon.maximumUses,
          productIds: coupon.productIds,
          refid: coupon.refId,
          startDate: coupon.startDate,
          status: coupon.status,
          subTitle: coupon.subTitle,
          title: coupon.title,
          typeOffer: coupon.typeOffer,
          sections: coupon.sections,
          unipay: coupon.unipay,
          lead: coupon?.lead,
          lineThrough: coupon?.lineThrough || []
        })
      })
    } else {
      couponsDataList?.map((coupon) => {
        coupons.push({
          id: coupon.id,
          channel: ChannelEnum[coupon?.channel ?? 'all'] || coupon?.channel,
          countUsed: coupon.countUsed,
          description: coupon.entry?.fields.description,
          endDate: coupon.endDate,
          image: coupon?.image || coupon?.imageDefault || COUPON_DEFAULT_IMAGE,
          legal: coupon.entry?.fields.legal,
          maximumUnits: coupon.maximumUses,
          maximumUses: coupon.maximumUses,
          productIds: coupon.productIds,
          refid: coupon.refid,
          startDate: coupon.startDate,
          status: statusMap[coupon?.status],
          subTitle: coupon.entry?.fields.subTitle,
          title: coupon.entry?.fields.title,
          typeOffer: coupon.typeOffer,
          sections: coupon.sections,
          unipay: coupon.unipay,
          lead: coupon?.entry?.fields?.lead,
          lineThrough: coupon?.entry?.fields?.lineThrough
        })
      })
    }
  }
  return coupons
}

export const mapProductsForCoupons = (
  products: IItem[],
  productsV2: IProductsV2[]
) => {
  return products.map((item) => {
    const itemV2 = productsV2.find((itemV2) => {
      return item?.itemId === itemV2?.item?.itemId
    })
    return {
      ...itemV2,
      ...item,
      detailUrl: item?.slug.split('/')[3]
    }
  })
}
