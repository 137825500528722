import {
  Certificates,
  Ingredients,
  NutritionalTable,
  Product,
  ProductDetailData,
  Warnings
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { IProductsV2 } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsV2'
import { Text } from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'

export const changeProductData = ({
  promotion,
  price,
  item,
  priceDetail
}: IProductsV2) => {
  const data: Product = {
    price: { ...price },
    priceDetail: { ...priceDetail },
    promotion: { ...promotion },
    sellers: [
      {
        ...price,
        sellerId: '1'
      }
    ],
    ...item
  }

  return { data }
}

export const filteredDetailedData = (detailedData: ProductDetailData) => {
  const warnings: Warnings[] = []
  const certificates: Certificates[] = []
  const ingredients: Array<Ingredients> = []

  if (!detailedData) {
    return {
      ingredients,
      certificates,
      nutritionalTablesSets: <NutritionalTable>{},
      warnings
    }
  }

  return {
    nutritionalTablesSets:
      detailedData?.nutritional_tables_sets || <NutritionalTable>{},
    certificates: detailedData?.certificates || certificates,
    warnings: detailedData?.warnings || warnings,
    ingredients: detailedData?.ingredients_sets?.[0]?.ingredients || ingredients
  }
}

export const getGeneralProps = (
  product: Product,
  isMobile: boolean,
  isPromotion: boolean
) => {
  const fontSizeMobileOffer: typeof Text.arguments.fontSize = isMobile
    ? 'sm'
    : '2xl'
  const promotionOffer = isPromotion ? false : product?.sellers[0]?.inOffer
  const typePromotion4: boolean = product?.priceDetail?.promotionType === 'mx$'
  const validateInOffer: boolean = typePromotion4 ? true : promotionOffer
  const validateListPrice: boolean =
    validateInOffer ||
    (product?.promotion?.['descriptionCode'] !== 3 &&
      product?.promotion?.['descriptionCode'] !== 0)
  const underlineInOffer: typeof Text.arguments.underline = typePromotion4
    ? 'none'
    : 'line-through'

  //validate netContent because in API V2 format param is not available
  const validateFormat =
    product?.measurementUnit?.toLowerCase() === 'kg'
      ? product['netContentLevelSmall']
      : product['netContent']

  return {
    fontSizeMobileOffer,
    promotionOffer,
    typePromotion4,
    underlineInOffer,
    validateInOffer,
    validateListPrice,
    validateFormat
  }
}

export const ingredientList = (ingredients: Array<Ingredients>): string => {
  if (!isValidArrayWithData(ingredients)) return ''
  return (
    ingredients
      .map((ingredient, index) => {
        const ingredientName = ingredient?.['ingredient_name']

        if (index === 0) {
          return ingredientName.replace(/^./, ingredientName[0].toUpperCase())
        }

        return ingredientName
      })
      .join(', ') + '.'
  )
}
