import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  Picture,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  IContentfulBasicEntry,
  IContentfulResponse,
  useUserClubUnimarc,
  useUserSavings,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { MembershipHeaderBrowse } from 'components/MembershipHeaderBrowse'
import { ClubAhorroContent } from '../ClubAhorroContent'
import { IAlliance } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IAlliance'
import {
  setMembershipBlock,
  transformMembershipDataCF
} from 'shared/helpers/membershipHeader'
import { getToken } from 'shared/helpers'
import { MembershipHeaderBackbone } from 'components/MembershipHeaderBrowse/MembershipHeaderBackbone/MembershipHeaderBackbone'
import { IUserClubUnimarc } from 'shared/interfaces/IMembership'
import {
  AllianceModalHandler,
  AllianceModalHandlerProps
} from 'components/AlliancePage/AllianceModal'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'

interface ClubUnimarcLoggedInProps {
  alliances?: IAlliance[]
  allianceProps?: AllianceModalHandlerProps
  appView: IContentfulBasicEntry<Record<string, unknown>>
  dataCFMembershipLeves: IContentfulResponse
  dataCFSaving: IContentfulResponse
  isMobile?: boolean
  onClick?: (allianceId?: string) => void
  onClickBanner?: () => void
  version?: string
}

export const ClubUnimarcLoggedIn = ({
  alliances,
  allianceProps,
  appView,
  dataCFMembershipLeves,
  dataCFSaving,
  isMobile,
  onClick,
  onClickBanner,
  version
}: ClubUnimarcLoggedInProps) => {
  const [membershipBlockData, setMembershipBlockData] = useState(null)
  const [membershipDataCF, setMembershipDataCF] = useState(null)
  const { data: userMembership, isLoading } = useUserWithMembership()
  const [userToken, setUserToken] = useState(null)

  const userClubUnimarc = useUserClubUnimarc(userToken)

  const userSavings = useUserSavings({
    reactQuery: {
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const membershipTadaBlock = isMobile ? 'blocks' : 'blocksDesktop'
  const bottomBanner = (
    appView?.[membershipTadaBlock] as IContentfulBasicEntry[]
  )?.filter((item) => {
    return item?.['label'] === 'Banner bottom'
  })?.[0]

  useEffect(() => {
    if (appView) {
      setMembershipDataCF(
        transformMembershipDataCF({
          data: appView?.[membershipTadaBlock],
          imageSaving: dataCFSaving,
          imageLevels: dataCFMembershipLeves,
          userMembership
        })
      )
    }
  }, [appView, isLoading])

  useEffect(() => {
    const getUserUnimarcData = async () => {
      if (!isLoading && !userToken) {
        const token = await getToken()
        setUserToken(token.token)
        userClubUnimarc.refetch()
      }
    }

    getUserUnimarcData()
  }, [userMembership, isLoading])

  useEffect(() => {
    if (membershipDataCF) {
      setMembershipBlockData(
        setMembershipBlock([membershipDataCF?.membershipButtons])
      )
    }
  }, [membershipDataCF])

  if (isLoading || userSavings.isLoading) {
    return <MembershipHeaderBackbone />
  }
  return (
    <Column alignItems='center'>
      <Column
        margin={isMobile ? '' : '0 0 60px 0'}
        maxWidth={getGlobalStyle('--width-max-desktop')}
        padding={isMobile ? '0 16px' : null}
      >
        <MembershipHeaderBrowse
          goalType={userClubUnimarc?.data?.goalType}
          levelGoal={userClubUnimarc?.data?.spendingGoal}
          marginTop='10px'
          membershipDataCF={membershipDataCF}
          shoppingGoal={userClubUnimarc?.data?.billsGoal}
          totalSavings={userSavings?.data?.totalSavings}
          userData={userMembership as IUserClubUnimarc}
        />
        <Spacer.Horizontal size={32} />
        <ClubAhorroContent
          alliances={alliances}
          appView={appView}
          isLoading={!alliances}
          isLoggedIn={true}
          isMobile={isMobile}
          membershipData={membershipBlockData}
          membershipLevel={userMembership?.membership}
          onClick={onClick}
          onClickBanner={onClickBanner}
          version={version}
        />
      </Column>
      {bottomBanner?.fields?.imageBanner?.[0]?.image?.fields?.file?.url && (
        <Container
          height='auto'
          margin='0 0 -2px 0'
          minHeight='170px'
          position='relative'
          width='100%'
        >
          <Picture
            height='auto'
            src={
              bottomBanner?.fields?.imageBanner?.[0]?.image?.fields?.file?.url
            }
            width='100%'
          />
        </Container>
      )}
      <AllianceModalHandler {...allianceProps} />
    </Column>
  )
}
