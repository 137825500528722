import { Container, Coupon } from '@smu-chile/pkg-unimarc-components'

export interface CouponSimpleListLoaderProps {
  length?: number
}

export const CouponSimpleListLoader = ({
  length = 4
}: CouponSimpleListLoaderProps) => {
  return (
    <>
      {[...Array(length)].map((key) => {
        return (
          <Container
            customHeight='100%'
            key={key}
          >
            <Coupon
              description=''
              isFetching
              subtitle=''
              title=''
            />
          </Container>
        )
      })}
    </>
  )
}
