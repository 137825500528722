import {
  Button,
  Column,
  Container,
  Divider,
  Icon,
  Modal,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import styles from './ClubUnimarcWelcomeModal.module.css'
import classNames from 'classnames'

export interface ClubUnimarcWelcomeModalProps {
  isLogged?: boolean
  isMobile?: boolean
  onButtonClick?: () => void
  onIconCloseClick?: () => void
  pictureUrl?: string
  showBack?: boolean
}

export const ClubUnimarcWelcomeModal = ({
  isMobile,
  onButtonClick,
  onIconCloseClick,
  pictureUrl,
  showBack
}: ClubUnimarcWelcomeModalProps) => {
  return (
    <Modal
      className={classNames(styles['modal__title'], {
        [styles['modal__mobile']]: isMobile
      })}
      draggButton={false}
      fullScreen={isMobile}
      isCloseIcon={false}
      isDraggable={true}
      isOpen
      maxWidth='440px'
      minHeightFullScreen='auto'
      padding='0'
      toggleOutside={onIconCloseClick}
    >
      <Column>
        <Row
          alignItems='center'
          justifyContent='between'
          padding='16px'
        >
          <div>{showBack && <Icon name='Back' />}</div>

          <Text
            fontSize='xl'
            fontWeight='semibold'
          >
            ¿Qué es el Club Unimarc?
          </Text>

          <Icon
            clickable='pointer'
            color={getGlobalStyle('--color-neutral-black')}
            customSize={18}
            margin='0 4px 0 0'
            name='Close'
            onClick={onIconCloseClick}
          />
        </Row>

        <Divider verticalMargin={0} />

        <Column
          alignItems='center'
          className={classNames(styles['modal__content'], {
            [styles['modal__content--mobile']]: isMobile
          })}
          gap={20}
          padding='24px 16px'
        >
          <Picture
            height='90px'
            objectFit='cover'
            src={pictureUrl}
            width='160px'
          />

          <Text
            display='block'
            lineHeight='17px'
            textAlign='center'
          >
            Ahora Club Ahorro es <b>Club Unimarc</b>. Descubre todos los nuevos
            beneficios y descuentos que tenemos para ti, ¡Altiro!
          </Text>

          <Text>
            ¿Cómo ahorras en&nbsp;<strong>Club Unimarc</strong>?
          </Text>

          <Container
            border={`1px solid ${getGlobalStyle('--color-neutral-gray-hover')}`}
            borderRadius={getGlobalStyle('--border-radius-md')}
            padding='16px 36px'
          >
            <ul>
              <li>
                <strong>Comprando</strong> en Unimarc
              </li>
              <li>
                Activando tus <strong>Cupones</strong> y canjeándolos
              </li>
              <li>
                Activando y utilizando tus <strong>Beneficios</strong>
              </li>
            </ul>
          </Container>
        </Column>
      </Column>

      <Column
        alignItems='center'
        padding='16px'
      >
        <Button
          borderRadius='24px'
          fontSize='lg'
          fontWeight='normal'
          fullWidth
          height='40px'
          label='Entendido'
          onClick={onButtonClick}
        />
      </Column>
    </Modal>
  )
}
