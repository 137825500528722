import {
  ButtonStatus,
  Container,
  MemoShelf,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { ShelvesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { statusPromotions } from 'shared/helpers'

export interface CouponDetailProductsProps {
  buttonLabel?: string
  buttonStatus?: ButtonStatus
  items: ShelvesData[]
  isLoading?: boolean
  onClickAdd?(payload?: unknown): void
  onClickItem?(payload?: unknown): void
  onClickRemove?(payload?: unknown): void
}

export const CouponDetailProducts = ({
  buttonLabel,
  buttonStatus,
  isLoading,
  items,
  onClickAdd,
  onClickItem,
  onClickRemove
}: CouponDetailProductsProps) => {
  const { innerWidth } = useMobile()

  const getCustomWidth = () => {
    return `${Math.floor(innerWidth / 300)}px`
  }

  const handleQuantityAdd = (payload: unknown) => {
    if (onClickAdd) {
      onClickAdd(payload)
    }
  }

  const handleShelfClick = (payload: unknown) => {
    if (onClickItem) {
      onClickItem(payload)
    }
  }

  const handleQuantityRemove = (payload: unknown) => {
    if (onClickRemove) {
      onClickRemove(payload)
    }
  }

  return (
    <Container isWrap>
      <Row isWrap>
        {items.map((item, index) => {
          const { isPromotion, offer, ppum, price, pricePromotion } =
            statusPromotions(item)

          const { images, name, promotion, sellers } = item
          const typePromotion = {
            '': 0,
            price: 1,
            percentage: 2,
            mxn: 3,
            mx$: 4,
            'm%n': 5
          }
          return (
            <MemoShelf
              {...item}
              buttonLabel={buttonLabel}
              buttonStatus={item?.buttonStatus ?? buttonStatus}
              customWidth={getCustomWidth()}
              handleOnClick={handleShelfClick}
              img={images[0]}
              inOffer={sellers[0]?.inOffer}
              isLoading={isLoading}
              isMobile
              isPromotion={isPromotion}
              key={index}
              likeListButtons={false}
              listPrice={sellers[0]?.listPrice}
              offer={offer}
              ppum={`(${ppum})`}
              ppumListPrice={`(${sellers[0]?.ppumListPrice})`}
              price={price}
              pricePromotion={pricePromotion}
              promotion={promotion?.['name']}
              quantityButtonProps={{
                ...item?.['quantityButtonProps'],
                handleChange: item?.['quantityButtonProps']?.handleChange,
                handleAdd: handleQuantityAdd,
                handleRemove: handleQuantityRemove
              }}
              quantityButtonStyles={{
                fontSize: 'md',
                margin: '0px',
                maxHeight: '30px',
                maxWidth: '100%',
                sizeCircle: 22
              }}
              showCouponButton={true}
              title={name}
              typePromotion={typePromotion[item?.priceDetail?.promotionType]}
            />
          )
        })}
      </Row>
    </Container>
  )
}
