import {
  Column,
  Container,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { OnlyOneBanner } from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/OnlyOneBanner'
import classNames from 'classnames'
import { BannersClubAhorro } from 'shared/interfaces/ClubUnimarc'
import styles from './FeaturedButtons.module.css'
import {
  BigScreen,
  SmallScreen,
  getBemId
} from '@smu-chile/pkg-unimarc-components/helpers'
import { slugify } from 'shared/helpers/slugify'
import { useRouter } from 'next/router'

export interface FeaturedButtonsProps {
  content: BannersClubAhorro
  isLoggedIn?: boolean
  isMobile?: boolean
  onClickAllianceBanner?: (allianceId?) => void
  onClickBanner?: () => void
}
export const FeaturedButtons = ({
  content,
  isLoggedIn,
  isMobile,
  onClickAllianceBanner,
  onClickBanner
}: FeaturedButtonsProps) => {
  const router = useRouter()
  const openSSO = content?.['imageBanner']?.[0]?.['showLoginScreen']

  const imageBanner = content?.['imageBanner']
  const subtitle = content?.['subtitle']
  const title = content?.['title']

  const hasHeader = !!title || !!subtitle

  const handleClickBanner = (url) => {
    const isRedirect = !/^\/alliance\?refId=/g.test(url)
    if (!isRedirect) {
      const alliance = url.split('=')[1]
      onClickAllianceBanner(alliance)
    } else {
      router.push(url)
    }
  }

  return (
    <Column tagName='section'>
      <BigScreen>
        <Column
          gap={40}
          id={getBemId('featureBanner', 'container')}
        >
          {hasHeader && (
            <>
              {isLoggedIn ? (
                <Column
                  alignItems='start'
                  id={getBemId('featureBanner', 'header')}
                  margin={isMobile ? '0 0 16px' : '0'}
                >
                  {title && (
                    <Text
                      fontSize={isMobile ? 'lg' : '2xl'}
                      fontWeight={isMobile ? 'medium' : 'semibold'}
                      id={getBemId('featureBanner', 'headerTitle')}
                    >
                      {title}
                    </Text>
                  )}

                  {subtitle && (
                    <Text
                      id={getBemId('featureBanner', 'headerSubtitle')}
                      lineHeight={isMobile ? '10px' : '24px'}
                    >
                      {subtitle}
                    </Text>
                  )}
                </Column>
              ) : (
                <Column
                  alignItems='center'
                  id={getBemId('featureBanner', 'header')}
                  margin={isMobile ? '0 0 16px' : '0'}
                >
                  {title && (
                    <Title
                      fontWeight='medium'
                      headingLevel='h1'
                      id={getBemId('featureBanner', 'headerTitle')}
                    >
                      {title}
                    </Title>
                  )}

                  {subtitle && (
                    <Text
                      id={getBemId('featureBanner', 'headerSubtitle')}
                      lineHeight={isMobile ? '10px' : '24px'}
                    >
                      {subtitle}
                    </Text>
                  )}
                </Column>
              )}
            </>
          )}

          {imageBanner &&
            Object.entries(imageBanner).map(([idBanner, banner]) => {
              const isOpenModal = /^\/alliance\?refId=/g.test(
                banner?.['referenceWeb']
              )
              return (
                <Container
                  className={classNames({
                    [styles['banner__image--clickable']]: openSSO
                  })}
                  id={getBemId('featureBanner', 'item', slugify(banner.label))}
                  key={idBanner}
                  onClick={openSSO ? onClickBanner : null}
                >
                  <OnlyOneBanner
                    banner={banner.image.fields.file.url}
                    hasURL={!!banner.referenceWeb}
                    onRedirect={() => {
                      isOpenModal && handleClickBanner(banner['referenceWeb'])
                    }}
                    url={!isOpenModal ? banner['referenceWeb'] : null}
                  />
                </Container>
              )
            })}
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column
          gap={24}
          id={getBemId('featureBanner', 'container')}
        >
          {hasHeader && (
            <Column id={getBemId('featureBanner', 'header')}>
              {title && (
                <Text
                  fontSize='lg'
                  fontWeight='medium'
                  id={getBemId('featureBanner', 'headerTitle')}
                >
                  {title}
                </Text>
              )}

              {subtitle && (
                <Text id={getBemId('featureBanner', 'headerSubtitle')}>
                  {subtitle}
                </Text>
              )}
            </Column>
          )}

          {imageBanner &&
            Object.entries(imageBanner).map(([idBanner, banner]) => {
              const isOpenModal = /^\/alliance\?refId=/g.test(
                banner?.['referenceWeb']
              )
              return (
                <Container
                  clickable={openSSO ? 'pointer' : ''}
                  customClassName={
                    styles['featureContentBanner__bannerContainer']
                  }
                  id={getBemId('featureBanner', 'item', slugify(banner.label))}
                  key={idBanner}
                  onClick={openSSO ? onClickBanner : null}
                >
                  <OnlyOneBanner
                    banner={banner.image.fields.file.url}
                    onRedirect={() => {
                      isOpenModal && handleClickBanner(banner['referenceWeb'])
                    }}
                    url={!isOpenModal ? banner['referenceWeb'] : null}
                  />
                </Container>
              )
            })}
        </Column>
      </SmallScreen>
    </Column>
  )
}
