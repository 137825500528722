import { useCallback, useEffect, useState } from 'react'
import { MemoShelf } from './Shelf'
import { ShelvesLoader } from './ShelvesLoader'
import {
  Data,
  Item
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniCart'
import {
  miniCartItemTagStructure,
  recursiveParentScrollable,
  shelvesImpression,
  useMiniCart,
  useUpdateQuantity
} from '@smu-chile/pkg-unimarc-hooks'
import { Item as ItemToSendNewAddToCart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

interface HandleOnSubmit {
  id: string
  seller: string
  quantity: number
  item: Item
}

export const Shelves = (): React.ReactElement => {
  const { data, isLoading } = useMiniCart()
  const { handleAddTempItem } = useUpdateQuantity()
  const [impressableShelves, setImpressableShelves] = useState([])
  const orderForm: Data = data?.data
  const site = 'Unimarc'

  const handleOnSubmit = useCallback(
    ({ quantity, item }: HandleOnSubmit) => {
      if (quantity != item?.quantity) {
        let totalQuantity = quantity
        orderForm?.items?.forEach((element) => {
          if (
            element?.id == item?.id &&
            element?.sellingPrice != item?.sellingPrice
          ) {
            totalQuantity += element.quantity
          }
        })

        const objectSendToNewAddToCart: ItemToSendNewAddToCart = {
          description: {
            brandName: item.brandName,
            categories: item.categories,
            id: item.id,
            image: item.image,
            measurementUnit: item.measurementUnit,
            name: item.name,
            netContent: undefined,
            note: '',
            productId: item.productId
          },
          price: {
            cartLimit: item.cartLimit,
            listPrice: item.listPrice,
            percentageDiscount: item.percentageDiscount,
            quantity: item.quantity,
            quantityKg: undefined,
            sellingPrice: item.sellingPrice,
            unitMultiplier: item.unitMultiplier
          }
        }

        handleAddTempItem({
          quantity: totalQuantity,
          item: objectSendToNewAddToCart
        })
      }
    },
    [handleAddTempItem]
  )

  useEffect(() => {
    if (
      !Array.isArray(orderForm?.items) ||
      !Array.isArray(impressableShelves) ||
      impressableShelves.length === 0 ||
      orderForm?.items.length !== impressableShelves.length
    )
      return

    const shelvesToImpress = impressableShelves.map((impressableShelve) => {
      return {
        ...impressableShelve,
        product: {
          ...miniCartItemTagStructure({ product: impressableShelve?.product }),
          listId: 'minicarro',
          listName: 'minicarro'
        }
      }
    })

    shelvesImpression({
      context: 'minicarro',
      currency: 'CLP',
      event: 'view_cart',
      parent: recursiveParentScrollable(impressableShelves[0].ref),
      shelves: shelvesToImpress,
      site,
      value: orderForm?.subtotal
    })
  }, [impressableShelves, orderForm?.items])

  if (!data?.data || isLoading) {
    return <ShelvesLoader />
  }

  const renderShelves = () => {
    const shelfsRef = []

    return orderForm?.items?.map((item, index) => {
      if (!item?.id) return null
      return (
        <MemoShelf
          catchShelfRef={(ref) => {
            if (ref) shelfsRef.push({ ref, product: item, position: index + 1 })
            if (shelfsRef.length === orderForm.items.length)
              setImpressableShelves(shelfsRef)
          }}
          handleOnSubmit={handleOnSubmit}
          item={item}
          key={`${item?.id}_shelf_${item?.sellingPrice}`}
          seller={orderForm?.seller}
        />
      )
    })
  }

  return <>{renderShelves()}</>
}
