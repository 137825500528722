import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import times from 'lodash.times'
import {
  Backbone,
  Button,
  Column,
  Container,
  CouponProps,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  useCoupons,
  useMobile,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import { ICouponObject } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import { CouponSimpleList } from 'components/CouponListPage/CouponSimpleList'
import { CouponDetail } from 'components/CouponDetail/CouponDetail'
import {
  getCouponProps,
  transformCoupons,
  useFetchCoupons,
  useHandleActiveCoupon,
  useHandleEvents,
  useLoadingCoupons
} from 'shared/helpers'
import { CouponCarousel } from './CouponCarousel'
import styles from './ClubAhorroCoupons.module.css'

interface ClubAhorroCouponsProps {
  title?: string
  titlePrepend?: ReactNode
  setTag?: () => void
  version: string
}

export const ClubAhorroCoupons = ({
  title = 'Mis Cupones',
  titlePrepend,
  setTag,
  version
}: ClubAhorroCouponsProps) => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const [couponsLoading, setCouponsLoading] = useState(true)
  const [loadingCoupon, setLoadingCoupon] = useState<string[]>([])
  const [selectedCouponId, setSelectedCouponId] = useState(null)

  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const couponsResult = useCoupons(version)
  const { isLoading } = couponsResult

  const [scrollToY, setScrollToY] = useState(0)

  useLoadingCoupons(couponsResult)
  useHandleEvents(setLoadingCoupon)

  const coupons = useMemo(() => {
    const couponsResultData = couponsResult.data?.data?.data
    if (isValidArrayWithData(couponsResultData)) {
      return transformCoupons(couponsResultData, version)
    }
    return []
  }, [couponsResult])

  const onClickViewCoupons = () => {
    router.push(process.env.NEXT_PUBLIC_COUPONSURL)
  }

  const handleActiveCoupon = useHandleActiveCoupon(
    isLoggedIn,
    coupons,
    loadingCoupon,
    selectedCouponId
  )

  const handleShowDetail = (couponData: ICouponObject) => {
    setSelectedCouponId(couponData.id)
    setScrollToY(window.scrollY)
  }
  const handleDetailActive = () => {
    setTag && setTag()
    handleActiveCoupon(selectedCouponId)
  }

  const handleDetailToggle = () => {
    setSelectedCouponId(null)
  }

  const mappedCoupons = useMemo(() => {
    return coupons.slice(0, 3).map<CouponProps>((couponItem, index) => {
      const coupon: CouponProps = {
        ...getCouponProps(couponItem, index),
        loading: loadingCoupon?.some((couponId) => {
          return couponId === couponItem?.id
        })
      }

      coupon.onClickViewProducts = () => {
        if (isMobile) {
          router.push(`/club-unimarc/cupones?refid=${couponItem.refid}&from=cu`)
        } else {
          handleShowDetail(couponItem)
        }
      }

      coupon.onClick = () => {
        setTag && setTag()
        return handleActiveCoupon(couponItem?.id)
      }

      return coupon
    })
  }, [coupons, handleActiveCoupon, loadingCoupon])

  const selectedCoupon = useMemo(() => {
    return coupons.find((couponItem) => {
      return couponItem?.id === selectedCouponId
    })
  }, [selectedCouponId, coupons])

  const desktopCoupons = useMemo(() => {
    return mappedCoupons.map((coupon) => {
      return {
        ...coupon,
        buttonHeight: '34px',
        customButtonFontWeight: getGlobalStyle('--font-weight-medium'),
        descriptionCompact: true,
        descriptionMinHeight: '62px',
        outlined: true,
        shadowned: false,
        showViewProducts: false
      }
    })
  }, [mappedCoupons])

  useFetchCoupons(
    isLoading,
    coupons,
    isLoggedIn,
    setCouponsLoading,
    couponsResult
  )

  const handleBack = () => {
    router.push('/')
  }

  useEffect(() => {
    if (!selectedCoupon) {
      window.scrollTo(0, scrollToY)
    } else {
      if (isMobile) window.scrollTo(0, 0)
    }
  }, [selectedCoupon])

  return (
    <Container
      justifyContent='center'
      margin={isMobile ? '32px 0 0' : ''}
      tagName='section'
    >
      <SmallScreen>
        {!selectedCoupon && (
          <Column>
            <Row
              alignItems='center'
              clickable='pointer'
              justifyContent='between'
              margin='0'
              onClick={handleBack}
            >
              <Container
                alignItems='center'
                className={styles['title__svg']}
                gap={3}
                id={getBemId('clubAhorroCoupons', 'title')}
                width='auto'
              >
                {titlePrepend}
                <Column>
                  <Text
                    fontSize='lg'
                    fontWeight='medium'
                  >
                    {title}
                  </Text>
                </Column>
              </Container>
              <Button
                borderRadius='24px'
                fontSize={getGlobalStyle('--font-size-sm')}
                fontWeight={getGlobalStyle('--font-weight-medium')}
                height='32px'
                id={getBemId('clubAhorroCoupons', 'seeMoreButton')}
                label='Ver todos'
                minWidth='80px'
                onClick={onClickViewCoupons}
                size='sm'
                type='secondary'
              />
            </Row>

            <Column
              margin='24px 0 0 -16px'
              width='100vw'
            >
              <CouponCarousel
                coupons={mappedCoupons}
                isLoading={couponsLoading}
              />
            </Column>
          </Column>
        )}
      </SmallScreen>

      <BigScreen>
        <Column gap={32}>
          {isLoading ? (
            <Row
              alignItems='center'
              justifyContent='between'
            >
              <Container maxWidth='256px'>
                <Backbone
                  borderRadius='18px'
                  height={18}
                />
              </Container>

              <Container maxWidth='106px'>
                <Backbone
                  borderRadius='18px'
                  height={40}
                  width={100}
                />
              </Container>
            </Row>
          ) : (
            <Column>
              <Row
                alignItems='center'
                gap={12}
                justifyContent={isLoggedIn ? 'between' : 'center'}
              >
                <Row
                  alignItems='center'
                  className={styles['title__svg']}
                  gap={8}
                  id={getBemId('clubAhorroCoupons', 'title')}
                  width='auto'
                >
                  {titlePrepend}
                  <Text
                    fontSize={isLoggedIn ? '2xl' : '3xl'}
                    fontWeight={isLoggedIn ? 'semibold' : 'medium'}
                  >
                    {title}
                  </Text>
                </Row>

                {isLoggedIn && (
                  <Button
                    borderRadius='24px'
                    fontSize={getGlobalStyle('--font-size-lg')}
                    fontWeight={getGlobalStyle('--font-weight-medium')}
                    id={getBemId('clubAhorroCoupons', 'seeMoreButton')}
                    label='Ver todos'
                    minWidth='106px'
                    onClick={onClickViewCoupons}
                    size='sm'
                    type='secondary'
                  />
                )}
              </Row>

              {!isLoggedIn && (
                <Container
                  justifyContent='end'
                  margin='-36px 0 0'
                >
                  <Button
                    borderRadius='24px'
                    fontSize={getGlobalStyle('--font-size-lg')}
                    fontWeight={getGlobalStyle('--font-weight-medium')}
                    id={getBemId('clubAhorroCoupons', 'seeMoreButton')}
                    label='Ver todos'
                    minWidth='106px'
                    onClick={onClickViewCoupons}
                    size='sm'
                    type='secondary'
                  />
                </Container>
              )}
            </Column>
          )}

          <Column
            gap='32px'
            grid
            gridTemplateColumns={3}
          >
            {couponsLoading ? (
              times(2, (i) => {
                return (
                  <Column
                    key={i}
                    maxWidth='270px'
                    position='relative'
                  >
                    <Container
                      backgroundColor='white'
                      borderRadius='15px'
                      left='auto'
                      margin='200px 0 0 -15px'
                      maxWidth='30px'
                      minHeight='30px'
                      position='absolute'
                      top='auto'
                      zIndex='1'
                    />
                    <Backbone
                      borderRadius='18px'
                      height={358}
                      width={100}
                    />
                    <Container
                      backgroundColor='white'
                      borderRadius='15px'
                      left='auto'
                      margin='200px 0 0 230px'
                      maxWidth='30px'
                      minHeight='30px'
                      position='absolute'
                      top='auto'
                      zIndex='1'
                    />
                  </Column>
                )
              })
            ) : (
              <CouponSimpleList
                coupons={desktopCoupons}
                isLoading={couponsLoading}
              />
            )}
          </Column>
        </Column>
      </BigScreen>

      <CouponDetail
        activeLoading={loadingCoupon.length > 0}
        coupon={selectedCoupon}
        onActive={handleDetailActive}
        onToggle={handleDetailToggle}
        open={!!selectedCoupon}
      />
    </Container>
  )
}
