import { AxiosRequestHeaders, QueryClient } from '@smu-chile/pkg-unimarc-hooks'
import { IDynamicObject } from 'shared/interfaces/IDynamicObject'
import {
  getDataApplyFilters,
  getInitialFilters,
  getQueryKeyToRequest
} from '../getDataFilters'
import { paginationCal } from '../paginationCal'
import { postProductsSearch } from '@smu-chile/pkg-unimarc-hooks/services/postProductsSearch'

interface GetSearchDataProps {
  query: string
  page: number
  headers: AxiosRequestHeaders
  params: IDynamicObject
}

export const getSearchData = async ({
  query = '',
  page = 1,
  headers = {},
  params
}: GetSearchDataProps) => {
  const queryClient = new QueryClient()
  const cleanedQuery = decodeURI(query)
  const { offset, end } = paginationCal({ page, limit: 50 })
  const initialLimit = 0
  const limit = end - initialLimit
  const initialFilters = getInitialFilters(params)
  const orderBy = params?.orderBy?.toString() ?? ''
  const { brand, format, category } = getDataApplyFilters(initialFilters)
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    { value: query },
    { value: offset },
    { value: limit }
  ])

  const intelliSearchKeyName = ['productsSearch-getSearch', keysToQuery]
  await queryClient.prefetchQuery(intelliSearchKeyName, () => {
    return postProductsSearch({
      brands: brand && [brand],
      categories: category,
      format: format && [format],
      from: offset.toString(),
      headers,
      orderBy,
      searching: cleanedQuery,
      to: limit.toString()
    })
  })
  const intelliSearchData = queryClient.getQueryData(intelliSearchKeyName)

  return {
    intelliSearchData,
    queryClient
  }
}
