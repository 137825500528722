import {
  breadcrumbJoin,
  dataLayerProductEvent,
  datalayerSimpleEvent,
  productProps
} from '@smu-chile/pkg-unimarc-hooks'

const handlePdpTags = ({ product, quantity, salesChannel }) => {
  const listId = 'PDP_' + breadcrumbJoin.replace(/\//g, '_')
  const listName = 'PDP-' + breadcrumbJoin.replace(/\//g, '-')
  const items = [
    productProps({
      product,
      listId,
      listName,
      quantity
    })
  ]
  dataLayerProductEvent({
    event: 'view_item',
    items,
    saleschannel: salesChannel || process.env.NEXT_PUBLIC_SALES_CHANNEL || '39'
  })
}

const clickPlp = (labelVal: string | number, event: string) => {
  const clickedText = labelVal == '/' ? 'Inicio' : labelVal
  const category = breadcrumbJoin?.search(/búsqueda/i) == -1 ? 'PLP' : 'SRP'
  datalayerSimpleEvent({
    event,
    eventCategory: event == 'breadcrumb' ? breadcrumbJoin : category,
    eventLabel: clickedText.toString()
  })
}

export { clickPlp, handlePdpTags }
