import HttpError from '@smu-chile/pkg-unimarc-hooks/shared/errors/HttpError'

const MAX_LOGIN_TRIES = 3
const LOGIN_RETRY_TIME_MS = 10_000
type ssoErrors = 'SSO_63' | 'SSO_60'

/**
 * Handle the errors from the response of login
 * @param {HttpError} httpError - Http error from login
 * @param {number} loginTries - How many times user has tried to login
 * @param {function} setError - Set the error type on error state
 * @param {function} tagLoginRef - Handle login analitycs
 * @param {function} tagLoginErrorCredentials - Handle error in credentials analitycs
 * @param {function} tagLoginErrorUnknown - Handle unknown error analitycs
 */
export const handleLoginErrors = (
  httpError: HttpError,
  loginTries,
  setError,
  tagLoginRef,
  tagLoginErrorCredentials,
  tagLoginErrorUnknown
) => {
  switch (httpError.status) {
    case 401:
      tagLoginErrorCredentials(tagLoginRef)
      if (loginTries.current === MAX_LOGIN_TRIES) {
        setError('rut', 'password')
      } else {
        setError('rut', 'credentials')
      }
      break
    case 404:
      tagLoginErrorCredentials(tagLoginRef)
      setError('rut', 'registration')
      break
    case 429:
      tagLoginErrorCredentials(tagLoginRef)
      setError('rut', 'atempts')
      break
    default:
      tagLoginErrorUnknown(tagLoginRef)
      setError('rut', 'data')
  }
}

/**
 * Track how many times user try to login
 * @param {number} loginTries - How many times user has tried to login
 * @param {Date} loginTries - Last time tha user try to login
 */
export const loginTriesAtempts = (loginTries, loginLastTry) => {
  if (loginTries.current === MAX_LOGIN_TRIES) {
    if (
      loginLastTry.current.valueOf() <
      new Date().valueOf() - LOGIN_RETRY_TIME_MS
    ) {
      loginTries.current = 0
    } else
      throw new HttpError('Has superado los intentos para ingresar', 429, {})
  }
}

/**
 * Track how many times user try to login
 * @param {number} loginTries - How many times user has tried to login
 * @param {Date} loginTries - Last time tha user try to login
 */
export const handleOTPErrors = (code: ssoErrors, setError) => {
  switch (code) {
    case 'SSO_63':
      setError('rut', 'otpCode')
      break
    case 'SSO_60':
      setError('rut', 'otpAtempts')
      break
    default:
      setError('rut', 'optData')
  }
}
