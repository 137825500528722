import {
  addToHomeData,
  getProductsMyUsualsV2,
  useEvents,
  useQuery,
  useSession,
  useUpdateFromShelf,
  visiblePromos
} from '@smu-chile/pkg-unimarc-hooks'
import React, { useState } from 'react'
import {
  useProdutsCarousel,
  useShelvesData
} from '../ProductsCarousel/helpers/useProdutsCarousel'
import { mapProductsToOldSchema } from '../../shared/helpers/mapProducts/mapProductsToOldSchema'
import {
  BackboneShelves,
  Column,
  Divider,
  Row,
  ShelfCarousel,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import Link from 'next/link'
import Image from 'next/future/image'

interface CarouselMyUsualProps {
  ean: string
  isMobile?: boolean
  customItemToSlide?: number
  customItemToShow?: number
  handleOpenMyListsModal?: (item: IProductInList) => void
}
export const CarouselMyUsualComponent = ({
  ean,
  isMobile,
  customItemToSlide,
  customItemToShow,
  handleOpenMyListsModal
}: CarouselMyUsualProps) => {
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const { isLoggedIn, isLoading: sessionLoading } = useSession()

  const { isLoading, data: productMyUsual } = useQuery(
    ['searches-my-usuals-v2', ean],
    () => {
      return getProductsMyUsualsV2() // productsMyUsualsV2() = retorna data mockeada ,  getMyUsualsV2() retorna la respuesta del servicio
    },
    { enabled: isLoggedIn }
  )
  const products = mapProductsToOldSchema(productMyUsual?.products || [])

  const handlePDPShelf = ({ ref, shelf }) => {
    addToHomeData({
      ref,
      promoData: { ...shelf, promoTitle: '' },
      isShelf: true
    })
  }

  const {
    dataPromotions,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    spacerBelow,
    resultPromotions
  } = useProdutsCarousel({ isMobile })

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading,
    products
  })

  const productsMemo =
    useShelvesData(shelvesData, dataPromotions, isMobile) || []
  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  if (!sessionLoading && !isLoggedIn) {
    return null
  }

  if (!Array.isArray(productsMemo) || productsMemo?.length === 0) {
    return null
  }

  const isLoadingMyUsual: boolean =
    isLoading ||
    Boolean(resultPromotions?.isLoading) ||
    Boolean(dataPromotions?.isLoading)

  const carouselMyUsualShelfData = {
    positionContainer,
    productsMemo,
    itemsToShow: customItemToShow || itemsToShow,
    itemsToSlide: customItemToSlide || itemsToSlide,
    spacerBelow,
    handleOnClick,
    handleAdd,
    handleRemove,
    handleChange
  }

  if (isLoadingMyUsual) {
    return (
      <BackboneShelves
        shelvesQuantityDesktop={5}
        shelvesQuantityMobile={2}
      />
    )
  }

  return (
    <>
      {Array.isArray(products) && products?.length > 0 && (
        <Row isWrap>
          {isMobile ? (
            <Column padding='0 20px 0 10px'>
              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={26}
              />
              <Title
                color={getGlobalStyle('--color-text-black')}
                customFontSize='16px'
                fontWeight='medium'
                headingLevel='h4'
                text='Te podría interesar'
              />
            </Column>
          ) : (
            <>
              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={26}
              />
              <Title
                color={getGlobalStyle('--color-text-black')}
                customFontSize='16px'
                fontWeight='medium'
                headingLevel='h4'
                text='Te podría interesar'
              />
              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={24}
              />
            </>
          )}
          <Row
            justifyContent='center'
            margin='0 0 12px'
            maxWidth='1120px'
          >
            <Column>
              <Row padding='10px'>
                <ShelfCarousel
                  carouselProps={{
                    infinite: true,
                    autoPlay: false,
                    shouldResetAutoplay: false,
                    afterChange: () => {
                      return visiblePromos({})
                    },
                    partialVisible: true
                  }}
                  catchShelfRef={handlePDPShelf}
                  containerProps={carouselMyUsualShelfData.positionContainer}
                  handleOnClick={carouselMyUsualShelfData.handleOnClick}
                  handleOpenMyListsModal={handleOpenMyListsModal}
                  isMobile={isMobile}
                  items={carouselMyUsualShelfData.productsMemo}
                  itemsToShow={carouselMyUsualShelfData.itemsToShow}
                  linkWrapper={Link}
                  nextImage={Image}
                  quantityButtonProps={{
                    handleAdd: carouselMyUsualShelfData.handleAdd,
                    handleRemove: carouselMyUsualShelfData.handleRemove,
                    handleChange: carouselMyUsualShelfData.handleChange
                  }}
                  quantityButtonStyles={{
                    fontSize: 'md',
                    margin: '0px',
                    maxHeight: '30px',
                    maxWidth: '163px',
                    sizeCircle: 22
                  }}
                  showAddToListButton={true}
                  showArrows={!isMobile}
                  showCouponButton={false}
                  slidesToSlide={carouselMyUsualShelfData.itemsToSlide}
                />
              </Row>
            </Column>
          </Row>
        </Row>
      )}
    </>
  )
}
function handleCarousel(prevState, nextState) {
  return prevState.ean === nextState.ean
}
export const CarouselMyUsual = React.memo(
  CarouselMyUsualComponent,
  handleCarousel
)
