import {
  Carousel,
  Column,
  Picture,
  Modal,
  ImageView,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { AlignItems } from '@smu-chile/pkg-unimarc-components/stories/layout/BaseContainer/BaseContainer'
import { useState } from 'react'
import styles from '../ProductDetailPage.module.css'

interface ImagesProps {
  alt: string
  alignSelf?: AlignItems
  alignItems?: AlignItems
  closePreviousModal?: boolean
  dots?: boolean
  handleModalClose?: () => void
  height?: string
  img: Array<string>
  isMobile?: boolean
  leftIcon?: boolean
  width?: string
  title: string
  stock: boolean
  padding?: string
  previewImgSize?: string
  pictureHeight?: string
  pictureWidth?: string
  productName?: string
  carouselContainerWidth?: string
  carouselContainerHeight?: string
  onClickPicture?: () => void
}

export const ProductDetailImage = ({
  alt,
  alignSelf,
  alignItems,
  closePreviousModal = false,
  dots = true,
  carouselContainerHeight = '400px',
  carouselContainerWidth = '400px',
  height = '100%',
  handleModalClose,
  img,
  isMobile = false,
  title,
  stock,
  padding = '0 0 120px 0',
  pictureHeight = '480px',
  pictureWidth = '480px',
  leftIcon = true,
  width = '100%',
  previewImgSize = '67px',
  productName = ''
}: ImagesProps) => {
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)

  const handleModalImageClose = () => {
    if (closePreviousModal)
      document.getElementById('sticky-buttons').style.display = 'block'
    setIsOpenModalDetail(false)
  }

  const handleCloseAll = () => {
    setIsOpenModalDetail(false)
    handleModalClose()
  }

  const handleModalOpen = () => {
    // hidde sticky buttons when modal is open
    if (closePreviousModal)
      document.getElementById('sticky-buttons').style.display = 'none'
    setIsOpenModalDetail(true)
  }

  const toggleFunction = !leftIcon ? handleModalImageClose : handleCloseAll

  return (
    <Column
      alignItems={alignItems}
      padding={padding}
    >
      {isMobile && productName && (
        <Modal
          customCloseIcon='Close'
          customCloseIconLeft='ArrowLeftNavigate2'
          customContainerClassName={styles.modalContainer}
          customIconLeft={leftIcon}
          draggButton={false}
          fullScreen
          iconLefttoggle={handleModalImageClose}
          iconPadding='10px 16px'
          isOpen={isOpenModalDetail}
          marginFullScreen='0 0 0 0'
          maxHeight='100%'
          maxWidth='max-content'
          openAnimation
          padding='20px 0 0 0'
          sizeIconClose={20}
          sizeIconLeft={40}
          toggle={toggleFunction}
          toggleOutside={toggleFunction}
        >
          <Row
            isWrap
            padding='10px 20px 0 20px'
          >
            <Row
              className={!stock ? styles.contentOutOfStock : ''}
              padding='20px 0 150px 0'
            >
              <ImageView
                height='320px'
                images={img}
                previewHeight='60px'
                previewWidth='60px'
                width='320px'
              />
            </Row>
          </Row>
        </Modal>
      )}

      {img?.length > 1 ? (
        <Carousel
          arrows={false}
          autoPlay={false}
          containerProps={{
            maxWidth: carouselContainerWidth,
            maxHeight: carouselContainerHeight,
            alignSelf: alignSelf
          }}
          infinite={false}
          itemDots={
            dots
              ? img.map((image) => {
                  return (
                    <Picture
                      alt={alt}
                      className={!stock ? styles.outOfStock : ''}
                      height={previewImgSize}
                      key={image}
                      src={image}
                      title={title}
                      width={previewImgSize}
                    />
                  )
                })
              : null
          }
          marginPreview='10px'
          preview
          previewSize={previewImgSize}
          showDots
          ssr
        >
          {img.map((image) => {
            return (
              <Picture
                alt={alt}
                className={!stock ? styles.outOfStock : ''}
                height={height}
                key={image}
                onClick={handleModalOpen}
                src={image}
                title={title}
                width={width}
              />
            )
          })}
        </Carousel>
      ) : (
        <Picture
          alt={alt}
          borderRadius={getGlobalStyle('--spacing-xs')}
          className={!stock ? styles.outOfStock : ''}
          height={pictureHeight}
          onClick={handleModalOpen}
          src={img?.[0]}
          title={title}
          width={pictureWidth}
        />
      )}
    </Column>
  )
}
